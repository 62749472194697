import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Button, Heading, Box } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "561px" },
    width: "100%",
    px: { base: "24px", md: "24px", lg: "10%" },
  },
  "inner-box": {
    mt: { base: "24px", md: "24px", lg: "0px" },
    width: "100%",
    height: "100%",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    justifyContent: "center",
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "56px" },
    color: "#000000",
    textAlign: { base: "center", md: "center", lg: "left" },
    mt: { base: "8px", md: "8px", lg: "0px" },
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  image: {
    textAlign: "center",
    ml: { base: "0px", md: "0px", lg: "50px" },
    height: { base: "auto", md: "auto", lg: "361px" },
    minWidth: { base: "100%", md: "100%", lg: "430px" },
    width: { base: "100%", md: "100%", lg: "430px" },
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    my: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },
}

const navigateToContactUs = () => navigate("/contact-us/")

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["icon-box"]}>
        <ChevronDownIcon sx={styles.icon} />
      </Flex>
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles.heading}>
          Bundled Payments for Care Improvement
        </Heading>
        <Button sx={styles.button} onClick={navigateToContactUs}>
          Schedule a Demo
        </Button>
      </Flex>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/bpci/bpci-hero-a.png"
          alt="BPCI-Advanced page hero image"
          placeholder="blurred"
        />
      </Box>
    </Flex>
  </Box>
)

export default SectionHero
